import {
    CTA,
    Infobox,
    InfoboxAppearance,
    InputAppearance,
    styled,
    Text,
    TextInput,
    TextTag,
} from '@volkswagen-onehub/components-core'

import axios from 'axios'

import React, {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {UserDataForm} from '../../types/userData'
import {RootState} from '../rootReducer'
import {Loading} from '../components/loading'
import {openAlertSystemLayer} from '../layers/launchers/AlertSystemLayerHandle'
import {showThanksRecoverPage} from '../../features/userData/userDataSlice'
import {AppDispatch} from '../thunk'
import {Global} from '../../shared/variables'
import {
    defaultModel,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import {LayerForm} from '../../shared/classes'
import store from '../store'

const FieldContainer = styled.div`
    margin: 0 0 ${(props) => props.theme.size.dynamic0100};
`
const Submit = styled(CTA)`
    display: block;
    margin: ${(props) => props.theme.size.static450} auto;
`

export function RecoverCouponForm(): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')
    const dispatch = useDispatch() as AppDispatch

    const [loadingRecoverForm, setLoadingRecoverForm] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [NotificationText, setNotificationText] = useState('')

    const backendUrl = useSelector(
        (state: RootState) => state.general.backendUrl
    )
    const campaignCode = useSelector(
        (state: RootState) => state.general.campaignCode
    )

    const cycleID = useSelector(
        (state: RootState) => state.userData.formCycleID
    )

    const {
        register,
        setError,
        handleSubmit,
        reset,
        formState,
        formState: {errors},
    } = useForm<UserDataForm>({
        mode: 'onChange',
    })

    const resetErrorNotification = () => {
        setShowNotification(false)
        setNotificationText('')
    }

    const recoverCoupon = (userData: UserDataForm) => {
        setLoadingRecoverForm(true)

        axios
            .get(
                `${backendUrl}/coupons/${campaignCode}/${userData.plate}/email`
            )
            .then(() => {
                setLoadingRecoverForm(false)
                dispatch(showThanksRecoverPage(true))

                trackingEvent(
                    TrackingAction.VW_BASIC_FORM_SUBMISSION_SUCCESS_MESSAGE_LOAD,
                    {
                        configuration: {
                            CarlineName: defaultModel(),
                        },
                        eventInfo: {
                            ...eventInfoCommon(),
                            contentId: t('layers.recoverCouponRequest.title'),
                        },
                        form: {
                            FormName:
                                store.getState().configurationPromo
                                    .configuration.tracking_application_id +
                                ' - ' +
                                t('layers.recoverCouponRequest.title'),
                            FormStart: false,
                            FormType: trackingData.FORM_TYPE,
                            CycleID: cycleID,
                        },
                        process: {
                            StepName: trackingData.STEP_NAME,
                            StepNumber: trackingData.STEP_NUMBER,
                        },
                    }
                )
                resetErrorNotification()
                reset()
            })
            .catch((err) => {
                setLoadingRecoverForm(false)
                if (err.response.status === Global.ERROR_404) {
                    setError(Global.PLATE, {
                        type: 'manual',
                        message: t('layers.alertSystem.textFailedRecover'),
                    })

                    trackingError(Global.PLATE)
                } else {
                    openAlertSystemLayer(
                        t('layers.alertSystem.textFailedRecover')
                    )
                }

                throw new Error(err)
            })
    }

    const trackingBasicClick = () => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_CTA_CLICK, {
            configuration: {
                CarlineName: defaultModel(),
            },
            eventInfo: {
                ...eventInfoCommon(),
                contentId: t('layers.recoverCouponRequest.title'),
            },
            form: {
                FormName:
                    store.getState().configurationPromo.configuration
                        .tracking_application_id +
                    ' - ' +
                    t('layers.recoverCouponRequest.title'),
                FormType: trackingData.FORM_TYPE,
            },
            link: {
                name: t('recover.submit'),
                url: window.location.href,
            },
        })
    }

    const trackingError = (errors: any) => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_ERROR_MESSAGE_LOAD, {
            form: {
                ErrorFormFields: [errors],
                CycleID: cycleID,
            },
        })
    }

    const onSubmit = (data: UserDataForm) => {
        resetErrorNotification()
        recoverCoupon(data)
        trackingBasicClick()
    }

    const onError = () => {
        trackingBasicClick()
        trackingError(Global.PLATE)
    }

    return (
        <>
            <LayerForm onSubmit={handleSubmit(onSubmit, onError)}>
                <FieldContainer>
                    <TextInput
                        {...register(Global.PLATE, {
                            required: {
                                value: true,
                                message: t('form.error.required'),
                            },
                        })}
                        appearance={
                            errors.plate
                                ? InputAppearance.Error
                                : InputAppearance.Default
                        }
                        message={
                            errors.plate ? errors.plate.message : undefined
                        }
                        label={t('recover.plate')}
                    />
                </FieldContainer>

                {showNotification ? (
                    <>
                        <Infobox
                            ariaLive="assertive"
                            appearance={InfoboxAppearance.Error}
                        >
                            <Text tag={TextTag.p}>{NotificationText}</Text>
                        </Infobox>
                    </>
                ) : undefined}

                <Submit
                    tag="button"
                    emphasis="primary"
                    title={t('recover.submit')}
                    disabled={!formState.isValid}
                    ariaLabel={t('recover.submit')}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('recover.submit'),
                        }}
                    />
                </Submit>
            </LayerForm>

            {loadingRecoverForm ? <Loading /> : undefined}
        </>
    )
}
