import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchAllProvinces} from '../../app/thunk'
import {Province} from '../../types/province'

export const provinceSlice = createSlice({
    name: 'provinces',
    initialState: {
        allProvinces: [],
    },
    reducers: {
        setProvinces(
            state: RootState,
            action: PayloadAction<Province[]>
        ): void {
            state.allProvinces = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchAllProvinces.fulfilled,
            (state: RootState, action) => {
                state.allProvinces = action.payload
            }
        )
    },
})

export const {setProvinces} = provinceSlice.actions

export default provinceSlice.reducer
