import React from 'react'
import {StarterFeatureAppDependencies} from './'

export const FeatureServicesContext = React.createContext<
    StarterFeatureAppDependencies | undefined
>(undefined)

export function useFeatureServices(): StarterFeatureAppDependencies {
    const featureServices = React.useContext(FeatureServicesContext)

    if (!featureServices) {
        throw new Error('No featureServices context value was provided.')
    }

    return featureServices
}
