import {
    InteractionLayerSizeV2,
    LayerContentOptionsV2,
    LayerManagerV2,
    ShimBackgroundTypeV2,
} from '@volkswagen-onehub/layer-manager'

import React from 'react'
import {Provider} from 'react-redux'
import store from '../../store'
import {RecoverCouponRequestLayer} from '../RecoverCouponRequest'
import {showThanksRecoverPage} from '../../../features/userData/userDataSlice'
import {setShowRecoverLayer} from '../../../features/general/generalSlice'

function recoverCouponRenderLayer(): React.JSX.Element {
    return (
        <Provider store={store}>
            <RecoverCouponRequestLayer />
        </Provider>
    )
}

let localLayerManager: LayerManagerV2
let layer: {close: () => void}

export const setRecoverCouponLayerHandle = (layerManager: LayerManagerV2) => {
    localLayerManager = layerManager
}

export const openRecoverCouponLayer = () => {
    if (!localLayerManager) return
    layer = localLayerManager.openInteractionLayer(
        recoverCouponRenderLayer,
        {} as LayerContentOptionsV2,
        {
            size: InteractionLayerSizeV2.C,
            shimBackgroundType: ShimBackgroundTypeV2.OPAQUE,
            userCloseable: true,
            onClose: () => {
                store.dispatch(showThanksRecoverPage(false))
            },
        }
    )
}

export const closeRecoverCouponLayer = () => {
    if (store.getState().general.isDealerWeb) {
        store.dispatch(setShowRecoverLayer(false))
        store.dispatch(showThanksRecoverPage(false))
    } else {
        layer.close()
    }
}
