import store from './store'
import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {openAlertSystemLayer} from './layers/launchers/AlertSystemLayerHandle'
import {ConfigurationPromo} from '../types/configurationPromo'
import {customPartner, Global, partner} from '../shared/variables'
import isEmpty from 'lodash.isempty'

export type AppDispatch = typeof store.dispatch

export const fetchConfigurationPromo = createAsyncThunk(
    'configurationPromo/fetchConfigurationPromo',
    async () => {
        const dynamicPartner = customPartner ? customPartner : partner

        try {
            const response = await axios.get<ConfigurationPromo>(
                `${store.getState().general.backendUrl}/promotions/${
                    store.getState().general.campaignCode
                }/${
                    typeof dynamicPartner.informationBnr !== 'undefined'
                        ? dynamicPartner.informationBnr
                        : ''
                }`
            )

            return response.data
        } catch (err: any) {
            if (err.response.status === Global.ERROR_404) {
                return
            } else {
                openAlertSystemLayer()
                throw err
            }
        }
    }
)

export const fetchAllProvinces = createAsyncThunk(
    'provinces/fetchAllProvinces',
    async () => {
        try {
            const response = await axios.get<ConfigurationPromo>(
                `${store.getState().general.backendUrl}/provinces`
            )
            return response.data
        } catch (err) {
            openAlertSystemLayer()
            throw err
        }
    }
)

export const fetchAllDealers = createAsyncThunk(
    'dealers/fetchAllDealers',
    async () => {
        const dealerSearchParams =
            store.getState().configurationPromo.configuration
                .dealers_search_params

        let dynamicFetchUrl = `${
            store.getState().general.backendUrl
        }/dealers?brand=${dealerSearchParams.brand}&activityType=${
            dealerSearchParams.activity_type
        }&contractType=${
            dealerSearchParams.contract_type
        }&orderby=cleanedCompany`

        if (!isEmpty(dealerSearchParams.blacklisted_codes)) {
            dynamicFetchUrl += `&not_company_codes=${dealerSearchParams.blacklisted_codes}`
        }

        try {
            const response = await axios.get<ConfigurationPromo>(
                dynamicFetchUrl
            )

            return response.data
        } catch (err) {
            openAlertSystemLayer()
            throw err
        }
    }
)
