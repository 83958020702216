import {getCustomPartner, getPartner, PARTNER_DEFAULT} from './utilities'

export enum Global {
    DEFAULT_LAYOUT = 'STANDALONE',
    DEALER_LAYOUT = 'DEALER',
    ERROR_422 = 422,
    ERROR_404 = 404,
    MINiMUM_FORM_FIELD_LENGTH = 2,
    PLATE = 'plate',
    NATIONAL_KEY = 'national',
    DW_SW_KEY = 'dw_sw',
    DEALER_WEB_SOURCE = 'dw',
    SERVICE_WEB_SOURCE = 'sw',
    CYCLE_ID_LENGTH = 12,
}

export const LIMIT_TEXT_AREA = 250

export enum IdkEndpoints {
    CSRF_TOKEN = 'csrf_token',
    USER = '/user',
}

const dealer_data_prefilled =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typeof dealer_data !== 'undefined' ? dealer_data : undefined
export const partner =
    typeof dealer_data_prefilled === 'undefined' || !dealer_data_prefilled
        ? PARTNER_DEFAULT
        : getPartner(dealer_data_prefilled)

const service_data_prefilled =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typeof service_data !== 'undefined' ? service_data : undefined

export const customPartner =
    typeof service_data_prefilled === 'undefined' || !service_data_prefilled
        ? undefined
        : getCustomPartner(service_data_prefilled)
