import {
    Container,
    ContainerGutter,
    ContainerPadding,
    CTA,
    styled,
    Text,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'
import {Alert} from '@volkswagen-onehub/icons-core'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface AlertSystemLayerProps {
    onClickClose: () => any
    state: any
}

const AlertIconContainer = styled(Text)`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    span {
        max-width: calc(100% - 80px);
    }
`
const AlertIcon = styled(Alert)`
    margin-right: ${(props) => props.theme.size.static250};
`

export function AlertSystemLayer(
    props: AlertSystemLayerProps
): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')

    return (
        <Container
            gutter={ContainerGutter.static400}
            horizontalAlign="center"
            padding={ContainerPadding.static450}
            verticalAlign="center"
            wrap="always"
            stretchContent
        >
            <Container gutter={ContainerGutter.static300} wrap="always">
                <AlertIconContainer
                    tag={TextTag.p}
                    appearance={TokenTextAppearance.copy200}
                >
                    <AlertIcon variant="large" />
                    <span
                        dangerouslySetInnerHTML={{
                            __html: props.state.message
                                ? props.state.message
                                : t(
                                      'layers.alertSystem.textErrorFailedResponse'
                                  ),
                        }}
                    />
                </AlertIconContainer>
                <CTA
                    stretchContent
                    emphasis="primary"
                    onClick={props.onClickClose}
                    tag="button"
                    title={t('layers.alertSystem.CTALabel')}
                    ariaLabel={t('layers.alertSystem.CTALabel')}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('layers.alertSystem.CTA'),
                        }}
                    />
                </CTA>
            </Container>
        </Container>
    )
}
