import {
    Breakpoints,
    Container,
    ContainerPadding,
    Text,
    styled,
    CTA,
    TextTag,
    TokenTextAppearance,
    LayoutRowGap,
    Layout,
} from '@volkswagen-onehub/components-core'
import {Close, Checkmark} from '@volkswagen-onehub/icons-core'

import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {CouponRequestForm} from '../form/CouponRequestForm'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import {setLoading} from '../../features/general/generalSlice'
import axios from 'axios'
import {openAlertSystemLayer} from './launchers/AlertSystemLayerHandle'
import {AppDispatch} from '../thunk'
import {CloseLayerCTA, ThanksTitle, TitleLayer} from '../../shared/classes'
import {closeCouponLayer} from './launchers/CouponRequestLayerHandle'
import {setResentEmail} from '../../features/userData/userDataSlice'
import {
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import store from '../store'
import {getSourceKey, getVersionKey} from '../../shared/utilities'

const ResentEmailTextContainer = styled(Text)`
    margin: ${(props) => props.theme.size.static300} 0 0;
`
const ResentEmailText = styled(Text)`
    display: flex;
    align-items: center;

    svg {
        margin-right: 10px;
        width: 32px;
    }
`

const FooterTextContainer = styled.div`
    border-top: 2px solid ${({theme}) => theme.interaction.recipe150.disabled};
    padding: ${(props) => props.theme.size.dynamic0250} 0 0;
    margin: ${(props) => props.theme.size.dynamic0250} 0 0;
`

const ContainerFooterCTA = styled.div`
    margin: ${(props) => props.theme.size.static400} 0 0;
`

export function CouponRequestLayer(): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')
    const dispatch = useDispatch() as AppDispatch

    const backendUrl = useSelector(
        (state: RootState) => state.general.backendUrl
    )
    const configurationPromo = useSelector(
        (state: RootState) => state.configurationPromo.configuration
    )
    const resentEmail = useSelector(
        (state: RootState) => state.userData.resentEmail
    )
    const thanksPage = useSelector(
        (state: RootState) => state.userData.thanksPage
    )
    const randomCode = useSelector(
        (state: RootState) => state.userData.randomCode
    )
    const personalData = useSelector(
        (state: RootState) => state.userData.personalData
    )
    const isDealerWeb = useSelector(
        (state: RootState) => state.general.isDealerWeb
    )

    const resendEmail = () => {
        axios
            .get(`${backendUrl}/coupons/${randomCode}/email`)
            .then(() => {
                dispatch(setResentEmail(true))

                trackingEvent(TrackingAction.VW_BASIC_LINK_CLICK, {
                    eventInfo: {
                        ...eventInfoCommon(),
                        contentId: t('layers.couponRequest.thanks.clickHere'),
                    },
                    link: {
                        name: t('layers.couponRequest.thanks.clickHere'),
                        newTab: false,
                        url: window.location.href,
                    },
                })
            })
            .catch((errors) => {
                dispatch(setLoading(false))

                openAlertSystemLayer()
                throw new Error(errors)
            })
    }

    const dynamicThankYouText = configurationPromo.thank_you_page.text[
        getVersionKey()
    ]
        .replace(':name', personalData.name)
        .replace(':surname', personalData.surname)
        .replace(':promotion', configurationPromo.name)

    useEffect(() => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_LAYER_LOAD, {
            eventInfo: {
                ...eventInfoCommon(),
                contentId:
                    store.getState().configurationPromo.configuration.form
                        .title[getVersionKey()],
            },
            form: {
                FormName:
                    store.getState().configurationPromo.configuration
                        .tracking_application_id +
                    ' - ' +
                    store.getState().configurationPromo.configuration.form
                        .title[getVersionKey()],
                FormStart: true,
                FormType: trackingData.FORM_TYPE,
                CycleID: store.getState().userData.formCycleID,
            },
            process: {
                Name: store.getState().configurationPromo.configuration.form
                    .title[getVersionKey()],
                StepName:
                    store.getState().configurationPromo.configuration.form
                        .title[getVersionKey()],
            },
        })
    }, [])

    return (
        <Container
            padding={
                !isDealerWeb
                    ? {
                          top: ContainerPadding.static250,
                          left: ContainerPadding.static250,
                          right: ContainerPadding.static250,
                          bottom: ContainerPadding.static450,
                      }
                    : undefined
            }
            wrap="always"
        >
            <Container horizontalAlign="flex-end">
                <CTA
                    tag="button"
                    emphasis="tertiary"
                    ariaLabel={t('cta.closeLayerLabel')}
                    iconPosition="top"
                    onClick={() => {
                        closeCouponLayer()
                        trackingEvent(
                            TrackingAction.VW_BASIC_FORM_LAYER_CLOSE_CLICK,
                            {
                                eventInfo: {
                                    ...eventInfoCommon(),
                                    contentId:
                                        store.getState().configurationPromo
                                            .configuration.form.title[
                                            getVersionKey()
                                        ],
                                },
                                form: {
                                    FormName:
                                        store.getState().configurationPromo
                                            .configuration
                                            .tracking_application_id +
                                        ' - ' +
                                        store.getState().configurationPromo
                                            .configuration.form.title[
                                            getVersionKey()
                                        ],
                                    FormStart: false,
                                    FormType: trackingData.FORM_TYPE,
                                },
                            }
                        )
                    }}
                    icon={<Close />}
                />
            </Container>

            <Container
                padding={
                    !isDealerWeb
                        ? {
                              left: {
                                  [Breakpoints.default]:
                                      ContainerPadding.grid001,
                                  [Breakpoints.b560]:
                                      ContainerPadding.static450,
                                  [Breakpoints.b1600]:
                                      ContainerPadding.static550,
                              },
                              right: {
                                  [Breakpoints.default]:
                                      ContainerPadding.grid001,
                                  [Breakpoints.b560]:
                                      ContainerPadding.static450,
                                  [Breakpoints.b1600]:
                                      ContainerPadding.static550,
                              },
                          }
                        : undefined
                }
            >
                {!thanksPage ? (
                    <>
                        <Layout rowGap={LayoutRowGap.static500}>
                            <TitleLayer
                                tag={TextTag.h3}
                                bold
                                appearance={TokenTextAppearance.headline300}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: configurationPromo.form.title[
                                            getVersionKey()
                                        ]
                                            ? configurationPromo.form.title[
                                                  getVersionKey()
                                              ]
                                            : undefined,
                                    }}
                                />
                            </TitleLayer>
                            <>
                                <Text
                                    tag={TextTag.p}
                                    appearance={TokenTextAppearance.copy200}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: configurationPromo.form
                                                .description[getVersionKey()]
                                                ? configurationPromo.form
                                                      .description[
                                                      getVersionKey()
                                                  ]
                                                : undefined,
                                        }}
                                    />
                                </Text>
                                <Text
                                    tag={TextTag.p}
                                    appearance={TokenTextAppearance.copy100}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: configurationPromo.form
                                                .field_notes
                                                ? configurationPromo.form
                                                      .field_notes
                                                : t(
                                                      'layers.couponRequest.required'
                                                  ),
                                        }}
                                    />
                                </Text>
                            </>
                        </Layout>
                        <CouponRequestForm />
                    </>
                ) : (
                    <>
                        <ThanksTitle
                            tag={TextTag.h3}
                            bold
                            appearance={TokenTextAppearance.headline300}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: configurationPromo.thank_you_page
                                        .title[getVersionKey()]
                                        ? configurationPromo.thank_you_page
                                              .title[getVersionKey()]
                                        : undefined,
                                }}
                            />
                        </ThanksTitle>

                        {configurationPromo && personalData ? (
                            <Text
                                tag={TextTag.p}
                                appearance={TokenTextAppearance.copy200}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: dynamicThankYouText
                                            ? dynamicThankYouText
                                            : undefined,
                                    }}
                                />
                            </Text>
                        ) : undefined}

                        {configurationPromo.thank_you_email.enabled &&
                        configurationPromo.thank_you_page.resending.enabled ? (
                            <ResentEmailTextContainer>
                                {resentEmail ? (
                                    <ResentEmailText
                                        tag={TextTag.p}
                                        bold
                                        appearance={TokenTextAppearance.copy200}
                                    >
                                        <Checkmark />
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: configurationPromo
                                                    .thank_you_page.resending
                                                    .confirmation_text[
                                                    getVersionKey()
                                                ]
                                                    ? configurationPromo
                                                          .thank_you_page
                                                          .resending
                                                          .confirmation_text[
                                                          getVersionKey()
                                                      ]
                                                    : undefined,
                                            }}
                                        />
                                    </ResentEmailText>
                                ) : (
                                    <Text
                                        tag={TextTag.p}
                                        appearance={TokenTextAppearance.copy200}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: configurationPromo
                                                    .thank_you_page.resending
                                                    .text[getVersionKey()]
                                                    ? configurationPromo
                                                          .thank_you_page
                                                          .resending.text[
                                                          getVersionKey()
                                                      ]
                                                    : undefined,
                                            }}
                                        />
                                        <CTA
                                            tag="button"
                                            emphasis="tertiary"
                                            size="small"
                                            onClick={resendEmail}
                                            ariaLabel={t(
                                                'layers.couponRequest.thanks.clickHereLabel'
                                            )}
                                            title={t(
                                                'layers.couponRequest.thanks.clickHereLabel'
                                            )}
                                        >
                                            {t(
                                                'layers.couponRequest.thanks.clickHere'
                                            )}
                                        </CTA>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'layers.couponRequest.thanks.text2'
                                                ),
                                            }}
                                        />
                                    </Text>
                                )}
                            </ResentEmailTextContainer>
                        ) : undefined}

                        {configurationPromo.thank_you_page.footer.enabled ? (
                            <FooterTextContainer>
                                <Text
                                    tag={TextTag.p}
                                    appearance={TokenTextAppearance.copy200}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: configurationPromo
                                                .thank_you_page.footer.text[
                                                getVersionKey()
                                            ]
                                                ? configurationPromo
                                                      .thank_you_page.footer
                                                      .text[getVersionKey()]
                                                : undefined,
                                        }}
                                    />
                                </Text>

                                <ContainerFooterCTA>
                                    <Layout rowGap={LayoutRowGap.static350}>
                                        {configurationPromo.thank_you_page
                                            .footer.cta ? (
                                            <Container horizontalAlign="center">
                                                <CTA
                                                    tag="a"
                                                    target={
                                                        isDealerWeb
                                                            ? '_self'
                                                            : '_blank'
                                                    }
                                                    emphasis="primary"
                                                    href={
                                                        configurationPromo
                                                            .thank_you_page
                                                            .footer.cta.href[
                                                            getSourceKey()
                                                        ]
                                                            ? configurationPromo
                                                                  .thank_you_page
                                                                  .footer.cta
                                                                  .href[
                                                                  getSourceKey()
                                                              ]
                                                            : undefined
                                                    }
                                                    title={
                                                        configurationPromo
                                                            .thank_you_page
                                                            .footer.cta.text[
                                                            getVersionKey()
                                                        ]
                                                            ? configurationPromo
                                                                  .thank_you_page
                                                                  .footer.cta
                                                                  .text[
                                                                  getVersionKey()
                                                              ]
                                                            : undefined
                                                    }
                                                    ariaLabel={
                                                        configurationPromo
                                                            .thank_you_page
                                                            .footer.cta.text
                                                            ? configurationPromo
                                                                  .thank_you_page
                                                                  .footer.cta
                                                                  .text
                                                            : undefined
                                                    }
                                                    onClick={() => {
                                                        trackingEvent(
                                                            TrackingAction.VW_BASIC_BUTTON_CLICK,
                                                            {
                                                                eventInfo: {
                                                                    ...eventInfoCommon(),
                                                                    contentId:
                                                                        configurationPromo
                                                                            .thank_you_page
                                                                            .footer
                                                                            .cta
                                                                            .text[
                                                                            getVersionKey()
                                                                        ],
                                                                },
                                                                link: {
                                                                    name: configurationPromo
                                                                        .thank_you_page
                                                                        .footer
                                                                        .cta
                                                                        .text,
                                                                    url: configurationPromo
                                                                        .thank_you_page
                                                                        .footer
                                                                        .cta
                                                                        .href,
                                                                    newTab: true,
                                                                },
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: configurationPromo
                                                                .thank_you_page
                                                                .footer.cta
                                                                .text[
                                                                getVersionKey()
                                                            ]
                                                                ? configurationPromo
                                                                      .thank_you_page
                                                                      .footer
                                                                      .cta.text[
                                                                      getVersionKey()
                                                                  ]
                                                                : undefined,
                                                        }}
                                                    />
                                                </CTA>
                                            </Container>
                                        ) : undefined}

                                        <Container horizontalAlign="center">
                                            <CTA
                                                tag="button"
                                                emphasis="secondary"
                                                onClick={() => {
                                                    closeCouponLayer()
                                                    trackingEvent(
                                                        TrackingAction.VW_BASIC_BUTTON_CLICK,
                                                        {
                                                            eventInfo: {
                                                                ...eventInfoCommon(),
                                                                contentId: t(
                                                                    'layers.couponRequest.thanks.cta'
                                                                ),
                                                            },
                                                            link: {
                                                                name: t(
                                                                    'layers.couponRequest.thanks.cta'
                                                                ),
                                                                url: window
                                                                    .location
                                                                    .href,
                                                                newTab: false,
                                                            },
                                                        }
                                                    )
                                                }}
                                                title={t(
                                                    'layers.couponRequest.thanks.ctaLabel'
                                                )}
                                                ariaLabel={t(
                                                    'layers.couponRequest.thanks.ctaLabel'
                                                )}
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: t(
                                                            'layers.couponRequest.thanks.cta'
                                                        ),
                                                    }}
                                                />
                                            </CTA>
                                        </Container>
                                    </Layout>
                                </ContainerFooterCTA>
                            </FooterTextContainer>
                        ) : (
                            <Container horizontalAlign="center">
                                <CloseLayerCTA
                                    tag="button"
                                    emphasis="secondary"
                                    onClick={() => closeCouponLayer()}
                                    title={t(
                                        'layers.couponRequest.thanks.ctaLabel'
                                    )}
                                    ariaLabel={t(
                                        'layers.couponRequest.thanks.ctaLabel'
                                    )}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                'layers.couponRequest.thanks.ctaLabel'
                                            ),
                                        }}
                                    />
                                </CloseLayerCTA>
                            </Container>
                        )}
                    </>
                )}
            </Container>
        </Container>
    )
}
