import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {UserDataForm} from '../../types/userData'

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        personalData: {} as UserDataForm,
        thanksPage: false,
        thanksRecoverPage: false,
        resentEmail: false,
        randomCode: '',
        privacyProfileVgiVersion: '',
        privacyMarketingVgiVersion: '',
        formCycleID: '',
        modelDisplayName: '',
        modelSlug: '',
    },
    reducers: {
        showThanksPage(
            state: RootState,
            action: PayloadAction<boolean | undefined>
        ): void {
            state.thanksPage = action.payload
        },
        showThanksRecoverPage(
            state: RootState,
            action: PayloadAction<boolean | undefined>
        ): void {
            state.thanksRecoverPage = action.payload
        },
        setPersonalData(
            state: RootState,
            action: PayloadAction<Record<string, never> | UserDataForm>
        ): void {
            state.personalData = action.payload
        },
        setRandomCode(state: RootState, action: PayloadAction<string>): void {
            state.randomCode = action.payload
        },
        setPrivacyProfileVgiVersion(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.privacyProfileVgiVersion = action.payload
        },
        setPrivacyMarketingVgiVersion(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.privacyMarketingVgiVersion = action.payload
        },
        setResentEmail(state: RootState, action: PayloadAction<boolean>): void {
            state.resentEmail = action.payload
        },
        setFormCycleID(state: RootState, action: PayloadAction<string>): void {
            state.formCycleID = action.payload
        },
        setSelectedModelDisplayName(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.modelDisplayName = action.payload
        },
        setSelectedModelSlug(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.modelSlug = action.payload
        },
    },
})

export const {
    setPersonalData,
    showThanksPage,
    setRandomCode,
    showThanksRecoverPage,
    setPrivacyProfileVgiVersion,
    setPrivacyMarketingVgiVersion,
    setResentEmail,
    setFormCycleID,
    setSelectedModelDisplayName,
    setSelectedModelSlug,
} = userDataSlice.actions
export default userDataSlice.reducer
