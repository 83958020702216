import {
    Breakpoints,
    Container,
    ContainerPadding,
    CTA,
    Layout,
    LayoutRowGap,
    Text,
    TokenTextAppearance,
    TextTag,
} from '@volkswagen-onehub/components-core'
import {Close} from '@volkswagen-onehub/icons-core'

import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {TitleLayer, CloseLayerCTA} from '../../shared/classes'
import {RecoverCouponForm} from '../form/RecoverCouponForm'
import {useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import {closeRecoverCouponLayer} from './launchers/RecoverCouponRequestLayerHandle'
import {
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'
import store from '../store'

export function RecoverCouponRequestLayer(): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')

    const thanksRecoverPage = useSelector(
        (state: RootState) => state.userData.thanksRecoverPage
    )
    const isDealerWeb = useSelector(
        (state: RootState) => state.general.isDealerWeb
    )

    useEffect(() => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_LAYER_LOAD, {
            eventInfo: {
                ...eventInfoCommon(),
                contentId: t('layers.recoverCouponRequest.title'),
            },
            form: {
                FormName:
                    store.getState().configurationPromo.configuration
                        .tracking_application_id +
                    ' - ' +
                    t('layers.recoverCouponRequest.title'),
                FormStart: true,
                FormType: trackingData.FORM_TYPE,
                CycleID: store.getState().userData.formCycleID,
            },
            process: {
                Name: t('layers.recoverCouponRequest.title'),
                StepName: t('layers.recoverCouponRequest.title'),
            },
        })
    }, [])

    return (
        <Container
            padding={
                !isDealerWeb
                    ? {
                          top: ContainerPadding.static250,
                          left: ContainerPadding.static250,
                          right: ContainerPadding.static250,
                          bottom: ContainerPadding.static450,
                      }
                    : undefined
            }
            wrap="always"
        >
            <Container horizontalAlign="flex-end">
                <CTA
                    tag="button"
                    emphasis="tertiary"
                    ariaLabel={t('cta.closeLayerLabel')}
                    iconPosition="top"
                    onClick={() => {
                        closeRecoverCouponLayer()
                        trackingEvent(
                            TrackingAction.VW_BASIC_FORM_LAYER_CLOSE_CLICK,
                            {
                                eventInfo: {
                                    ...eventInfoCommon(),
                                    contentId: t(
                                        'layers.recoverCouponRequest.title'
                                    ),
                                },
                                form: {
                                    FormName:
                                        store.getState().configurationPromo
                                            .configuration
                                            .tracking_application_id +
                                        ' - ' +
                                        t('layers.recoverCouponRequest.title'),
                                    FormStart: false,
                                    FormType: trackingData.FORM_TYPE,
                                },
                            }
                        )
                    }}
                    icon={<Close />}
                />
            </Container>

            <Container
                padding={
                    !isDealerWeb
                        ? {
                              left: {
                                  [Breakpoints.default]:
                                      ContainerPadding.grid001,
                                  [Breakpoints.b560]:
                                      ContainerPadding.static450,
                                  [Breakpoints.b1600]:
                                      ContainerPadding.static550,
                              },
                              right: {
                                  [Breakpoints.default]:
                                      ContainerPadding.grid002,
                                  [Breakpoints.b560]:
                                      ContainerPadding.static450,
                                  [Breakpoints.b1600]:
                                      ContainerPadding.static550,
                              },
                          }
                        : undefined
                }
            >
                {!thanksRecoverPage ? (
                    <Layout rowGap={LayoutRowGap.static500}>
                        <TitleLayer
                            tag={TextTag.h3}
                            bold
                            appearance={TokenTextAppearance.headline300}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'layers.recoverCouponRequest.title'
                                    ),
                                }}
                            />
                        </TitleLayer>
                        <>
                            <Text
                                tag={TextTag.p}
                                appearance={TokenTextAppearance.copy200}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'layers.recoverCouponRequest.text'
                                        ),
                                    }}
                                />
                            </Text>
                            <Text
                                tag={TextTag.p}
                                appearance={TokenTextAppearance.copy100}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'layers.couponRequest.required'
                                        ),
                                    }}
                                />
                            </Text>
                        </>

                        <RecoverCouponForm />
                    </Layout>
                ) : (
                    <>
                        <TitleLayer
                            tag={TextTag.h3}
                            bold
                            appearance={TokenTextAppearance.headline300}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'layers.recoverCouponRequest.thanks.title'
                                    ),
                                }}
                            />
                        </TitleLayer>
                        <Text
                            tag={TextTag.p}
                            appearance={TokenTextAppearance.copy200}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'layers.recoverCouponRequest.thanks.text'
                                    ),
                                }}
                            />
                        </Text>

                        <CloseLayerCTA
                            tag="button"
                            emphasis="secondary"
                            onClick={() => closeRecoverCouponLayer()}
                            title={t('layers.couponRequest.thanks.ctaLabel')}
                            ariaLabel={t(
                                'layers.couponRequest.thanks.ctaLabel'
                            )}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'layers.couponRequest.thanks.cta'
                                    ),
                                }}
                            />
                        </CloseLayerCTA>
                    </>
                )}
            </Container>
        </Container>
    )
}
