import {
    CTA,
    Select,
    styled,
    Text,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'

import {Map} from '@volkswagen-onehub/icons-core'

import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Province} from '../../types/province'
import {useSelector} from 'react-redux'
import {RootState} from '../rootReducer'
import isEmpty from 'lodash.isempty'
import {DealerData} from '../../types/dealer'
import {capitalizeWords} from '../../shared/utilities'
import {Loading} from '../components/loading'

const ResultViewContainer = styled.div`
    margin-top: ${(props) => props.theme.size.dynamic0200};
`
const DealerResult = styled.div`
    padding-bottom: ${(props) => props.theme.size.static250};
    margin-bottom: ${(props) => props.theme.size.static400};
    border-bottom: 1px solid ${(props) => props.theme.colors.border.default};
    display: grid;
    gap: 6px;
`
const ResultViewContainerTitle = styled(Text)`
    margin-bottom: ${(props) => props.theme.size.static400};
`

export function DealerView(): React.ReactElement {
    const {t} = useTranslation('fa-coupon')

    const allProvinces = useSelector(
        (state: RootState) => state.provinces.allProvinces
    )
    const allDealers = useSelector(
        (state: RootState) => state.dealers.allDealers
    )
    const [filteredDealers, setFilteredDealers] = useState([])
    const [searchActive, setSearchActive] = useState(false)
    const filterDealersByProvince = (province: string) => {
        setFilteredDealers(
            allDealers.filter(
                (dealer: DealerData) => dealer.provinceCode === province
            )
        )
    }

    return (
        <>
            {!isEmpty(allProvinces) && !isEmpty(allDealers) ? (
                <div>
                    <Select
                        defaultValue=""
                        onChange={(val) => {
                            setSearchActive(true)
                            filterDealersByProvince(val.currentTarget.value)
                        }}
                        label={t('form.province.label')}
                    >
                        <option value="">{t('form.province.default')}</option>
                        {allProvinces.map((option: Province) => (
                            <option value={option.code} key={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </Select>

                    {searchActive ? (
                        <ResultViewContainer>
                            <ResultViewContainerTitle
                                tag={TextTag.p}
                                bold
                                appearance={TokenTextAppearance.headline200}
                            >
                                {!isEmpty(filteredDealers) ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('dealerView.title'),
                                        }}
                                    />
                                ) : (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                'dealerView.titleNoResults'
                                            ),
                                        }}
                                    />
                                )}
                            </ResultViewContainerTitle>

                            {!isEmpty(filteredDealers) && searchActive ? (
                                <>
                                    {filteredDealers.map(
                                        (dealer: DealerData) => (
                                            <DealerResult key={dealer.id}>
                                                <Text
                                                    tag={TextTag.h3}
                                                    bold
                                                    appearance={
                                                        TokenTextAppearance.headline100
                                                    }
                                                >
                                                    {capitalizeWords(
                                                        dealer.cleanedCompany
                                                    )}
                                                </Text>
                                                <Text
                                                    tag={TextTag.p}
                                                    appearance={
                                                        TokenTextAppearance.headline100
                                                    }
                                                >
                                                    {capitalizeWords(
                                                        `${dealer.address} ${
                                                            dealer.streetNumber
                                                                ? dealer.streetNumber +
                                                                  ', '
                                                                : ''
                                                        } ${dealer.city}`
                                                    )}
                                                </Text>
                                                <CTA
                                                    tag="a"
                                                    target="_blank"
                                                    emphasis="tertiary"
                                                    href={`https://www.volkswagen.it/it/rete-volkswagen/informazioni.html/__app/${dealer.companyCode}.app`}
                                                    ariaLabel={t(
                                                        'dealerView.map'
                                                    )}
                                                    icon={<Map ariaHidden />}
                                                    iconPosition="end"
                                                >
                                                    {t('dealerView.map')}
                                                </CTA>
                                            </DealerResult>
                                        )
                                    )}
                                </>
                            ) : undefined}
                        </ResultViewContainer>
                    ) : undefined}
                </div>
            ) : (
                <Loading />
            )}
        </>
    )
}
