import {combineReducers} from '@reduxjs/toolkit'

import {generalSlice} from '../features/general/generalSlice'
import {userDataSlice} from '../features/userData/userDataSlice'
import {configurationPromoSlice} from '../features/configurationPromo/configurationPromoSlice'
import {provinceSlice} from '../features/provinces/provinceSlice'
import {dealerSlice} from '../features/dealers/dealerSlice'

const rootReducer: any = combineReducers({
    general: generalSlice.reducer,
    userData: userDataSlice.reducer,
    configurationPromo: configurationPromoSlice.reducer,
    provinces: provinceSlice.reducer,
    dealers: dealerSlice.reducer,
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
