import {
    LayerCloseHandleV2,
    LayerHandleV2,
    LayerManagerV2,
} from '@volkswagen-onehub/layer-manager'
import React from 'react'
import {Provider} from 'react-redux'
import store from '../../store'
import {AlertSystemLayer} from '../AlertSystemLayer'

function AlertSystemRenderLayer(
    state: any,
    close: LayerCloseHandleV2<any>
): React.JSX.Element {
    return (
        <Provider store={store}>
            <AlertSystemLayer
                state={state}
                onClickClose={() => close('layerContent')}
            />
        </Provider>
    )
}

let localLayerManager: LayerManagerV2
let layer: LayerHandleV2<{message: string | undefined}>

export const setAlertSystemLayerHandle = (layerManager: LayerManagerV2) => {
    localLayerManager = layerManager
}

export const openAlertSystemLayer = (message?: string) => {
    layer = localLayerManager.openAlert(AlertSystemRenderLayer, {
        message,
    })
}

export const closeAlertSystemLayer = () => {
    layer.close()
}
