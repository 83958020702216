import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchConfigurationPromo} from '../../app/thunk'

export const configurationPromoSlice = createSlice({
    name: 'general',
    initialState: {
        configuration: {},
        urlParameters: {},
    },
    reducers: {
        setConfiguration(
            state: RootState,
            action: PayloadAction<string>
        ): void {
            state.configuration = action.payload
        },
        setUrlParameters(
            state: RootState,
            action: PayloadAction<object>
        ): void {
            state.urlParameters = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchConfigurationPromo.fulfilled,
            (state: RootState, action) => {
                state.configuration = action.payload
            }
        )
    },
})

export const {setUrlParameters} = configurationPromoSlice.actions

export default configurationPromoSlice.reducer
