import {FeatureAppDefinition, FeatureServices, Logger} from '@feature-hub/core'
import {ReactFeatureApp} from '@feature-hub/react'
import React from 'react'
import {App} from './app/app'
import {FeatureServicesContext} from './use-feature-services'
import './i18n'
import store from './app/store'
import {Provider} from 'react-redux'
import {LayerManagerV2} from '@volkswagen-onehub/layer-manager'
import {ThemeProvider} from '@volkswagen-onehub/components-core'
import {Global} from './shared/variables'

export interface StarterFeatureAppDependencies extends FeatureServices {
    readonly 's2:logger'?: Logger
    readonly 'layer-manager'?: LayerManagerV2
}

export interface FeatureAppCouponConfig {
    backendUrl?: string
    campaignCode: string
    privacyProfileVgiVersion: string
    privacyMarketingVgiVersion: string
    source: string
    priceList?: string
    layout: string
}

export const featureAppDefinition: FeatureAppDefinition<
    ReactFeatureApp,
    StarterFeatureAppDependencies,
    FeatureAppCouponConfig
> = {
    optionalDependencies: {
        featureServices: {
            's2:logger': '^1.0.0',
            'fa-content-service': '^1.0.0',
            'layer-manager': '^2.5.0',
            'service-config-provider': '1.0.0',
            'vw:authService': '^2.0.0',
            'gfa:auth-service': '^1.0.0',
            'vw:authservice-config': '^2.0.0',
            'tracking': '^1.0.0',
            'ipc-service': '^1.0.0',
        },
    },
    create: ({featureAppId, featureServices, config}) => {
        const faContentService = featureServices['fa-content-service'] as any

        const backendUrl =
            (faContentService && faContentService.content?.backendUrl) ||
            config?.backendUrl

        const source =
            (faContentService && faContentService.content?.source) ||
            config?.source

        const priceList =
            (faContentService && faContentService.content?.priceList) ||
            config?.priceList

        const campaignCode =
            (faContentService && faContentService.content?.campaignCode) ||
            config?.campaignCode

        const layout =
            (faContentService && faContentService.content?.layout) ||
            config?.layout ||
            Global.DEFAULT_LAYOUT

        const privacyProfileVgiVersion =
            (faContentService &&
                faContentService.content?.privacyProfileVgiVersion) ||
            config?.privacyProfileVgiVersion

        const privacyMarketingVgiVersion =
            (faContentService &&
                faContentService.content?.privacyMarketingVgiVersion) ||
            config?.privacyMarketingVgiVersion

        if (!backendUrl) {
            throw new Error('No backendUrl is configured!')
        }
        if (!privacyProfileVgiVersion) {
            throw new Error('No privacyProfileVgiVersion is configured!')
        }

        if (!privacyMarketingVgiVersion) {
            throw new Error('No privacyMarketingVgiVersion is configured!')
        }

        if (!layout) {
            throw new Error('No layout is configured!')
        }

        return {
            render() {
                return (
                    <FeatureServicesContext.Provider value={featureServices}>
                        <ThemeProvider theme="main">
                            <Provider store={store}>
                                <App
                                    featureAppId={featureAppId}
                                    backendUrl={backendUrl}
                                    campaignCode={campaignCode}
                                    source={source}
                                    priceList={priceList}
                                    layout={layout}
                                    privacyProfileVgiVersion={
                                        privacyProfileVgiVersion
                                    }
                                    privacyMarketingVgiVersion={
                                        privacyMarketingVgiVersion
                                    }
                                />
                            </Provider>
                        </ThemeProvider>
                    </FeatureServicesContext.Provider>
                )
            },
        }
    },
}

export default featureAppDefinition
