import {CTA, styled, Text} from '@volkswagen-onehub/components-core'

export const TitleLayer = styled(Text)`
    margin: ${(props) => props.theme.size.static450} auto
        ${(props) => props.theme.size.static150};
`

export const ThanksTitle = styled(Text)`
    margin: 0 0 ${(props) => props.theme.size.static500};
`

export const CloseLayerCTA = styled(CTA)`
    display: block;
    margin: ${(props) => props.theme.size.static450} auto
        ${(props) => props.theme.size.static150};
`
export const LayerForm = styled.form`
    label p {
        line-height: 14px;
    }
    &.no-logged {
        margin-top: ${(props) => props.theme.size.static500};
    }
`
