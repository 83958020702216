import {
    Breakpoints,
    Checkbox,
    CTA,
    Fieldset,
    Infobox,
    InfoboxAppearance,
    InputAppearance,
    Select,
    styled,
    Text,
    TextArea,
    TextInput,
    TextTag,
    TokenTextAppearance,
} from '@volkswagen-onehub/components-core'

import axios from 'axios'

import React, {useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {UserDataForm} from '../../types/userData'
import {RootState} from '../rootReducer'
import {Loading} from '../components/loading'
import {openAlertSystemLayer} from '../layers/launchers/AlertSystemLayerHandle'
import {
    setPersonalData,
    setRandomCode,
    setSelectedModelDisplayName,
    setSelectedModelSlug,
    showThanksPage,
} from '../../features/userData/userDataSlice'
import {AppDispatch} from '../thunk'
import {
    authProxyUserEndpoint,
    axiosInstance,
    csrfToken,
} from '../../shared/authServiceHelper'
import {openDisclaimerLayer} from '../layers/launchers/DisclaimerLayerHandle'
import {setLoading} from '../../features/general/generalSlice'
import {Province} from '../../types/province'
import {DealerData} from '../../types/dealer'
import isEmpty from 'lodash.isempty'
import {Global, LIMIT_TEXT_AREA} from '../../shared/variables'
import {
    defaultModel,
    eventInfoCommon,
    TrackingAction,
    trackingData,
    trackingEvent,
} from '../../shared/trackingServiceHelper'

import store from '../store'
import {setSelectedDealerData} from '../../features/dealers/dealerSlice'
import {LayerForm} from '../../shared/classes'
import {
    capitalizeWords,
    getDealerInfoOption,
    getVersionKey,
} from '../../shared/utilities'

const FieldContainer = styled.div`
    margin: 0 0 ${(props) => props.theme.size.dynamic0100};
`
const FormFieldsContainer = styled.div`
    margin: ${(props) => props.theme.size.static600} 0
        ${(props) => props.theme.size.dynamic0150};

    @media (min-width: ${Breakpoints.b560}px) {
        margin: ${(props) => props.theme.size.dynamic0300} 0 0;
    }
`
const FormFieldsContainerTitle = styled(Text)`
    margin: 0 0 ${(props) => props.theme.size.static300};
`
const PrivacyText = styled(Text)`
    margin: ${(props) => props.theme.size.static150} 0
        ${(props) => props.theme.size.static350};
`
const SubmitContainer = styled.div`
    display: block;
    margin: ${(props) => props.theme.size.static450} auto;
`
const PreCompiledCTA = styled(CTA)`
    margin: ${(props) => props.theme.size.dynamic0100} 0
        ${(props) => props.theme.size.dynamic0200};
`
const CTAVum = styled(CTA)`
    display: block;
    margin: 0 0 ${(props) => props.theme.size.static400} auto;
    color: ${({theme}) => theme.interaction.recipe300.disabled};
`
const FieldTextInputUppercase = styled(FieldContainer)`
    input {
        text-transform: uppercase;
    }
`
export function CouponRequestForm(): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')
    const dispatch = useDispatch() as AppDispatch

    const [loadingForm, setLoadingForm] = useState(false)
    const [selectedDealer, setSelectedDealer] = useState<DealerData | null>(
        null
    )
    const [showNotification, setShowNotification] = useState(false)
    const [NotificationText, setNotificationText] = useState('')

    const backendUrl = useSelector(
        (state: RootState) => state.general.backendUrl
    )
    const logged = useSelector((state: RootState) => state.general.logged)

    const configuration = useSelector(
        (state: RootState) => state.configurationPromo.configuration
    )

    const cycleID = useSelector(
        (state: RootState) => state.userData.formCycleID
    )

    const labelSubmit = useSelector(
        (state: RootState) =>
            state.configurationPromo.configuration.form.cta_submit[
                getVersionKey()
            ]
    )
    const allProvinces = useSelector(
        (state: RootState) => state.provinces.allProvinces
    )
    const additionalInfoField = useSelector(
        (state: RootState) =>
            state.configurationPromo.configuration.form.additional_info_field
    )
    const plateField = useSelector(
        (state: RootState) =>
            state.configurationPromo.configuration.form.plate_field
    )
    const modelsField = useSelector(
        (state: RootState) =>
            state.configurationPromo.configuration.form.models_field
    )
    const allDealers = useSelector(
        (state: RootState) => state.dealers.allDealers
    )
    const isDealerWeb = useSelector(
        (state: RootState) => state.general.isDealerWeb
    )
    const prefilledDealerData = useSelector(
        (state: RootState) => state.dealers.prefilledDealerData
    )
    const source = useSelector((state: RootState) => state.general.source)
    const priceListData = useSelector(
        (state: RootState) => state.general.priceListData
    )

    const [filteredDealers, setFilteredDealers] = useState([])

    const filterDealersByProvince = (province: string) => {
        setFilteredDealers(
            allDealers.filter(
                (dealer: DealerData) => dealer.provinceCode === province
            )
        )
    }
    const saveDealerSelected = (dealerCodeSelected: string) => {
        setSelectedDealer(
            allDealers.find(
                (dealer: DealerData) =>
                    dealer.companyCode === dealerCodeSelected
            )
        )
    }

    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        clearErrors,
        control,
        formState: {errors},
    } = useForm<UserDataForm>({
        mode: 'onChange',
    })

    const showBackendValidationForm = (field: string, errors: any) => {
        if (field in errors) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(field, {
                type: 'manual',
                message: errors[String(field)].map((el: string) => {
                    return el
                }),
            })
        }
    }

    const resetErrorNotification = () => {
        setShowNotification(false)
        setNotificationText('')
    }

    const getDynamicDealerCode = () => {
        return source === Global.DEALER_WEB_SOURCE ||
            source === Global.SERVICE_WEB_SOURCE
            ? prefilledDealerData.informationBnr
            : selectedDealer?.companyCode
    }

    const getDynamicDealerName = () => {
        return source === Global.DEALER_WEB_SOURCE ||
            source === Global.SERVICE_WEB_SOURCE
            ? prefilledDealerData.informationName
            : selectedDealer?.company
    }

    const createCoupon = (userData: UserDataForm) => {
        setLoadingForm(true)

        console.log(userData)

        axios
            .post(`${backendUrl}/coupons`, {
                name: userData.name,
                campaign_code: configuration.code,
                surname: userData.surname,
                email: userData.email,
                is_dealerweb: isDealerWeb,
                telephone: userData.telephone,
                ...(store.getState().userData.modelDisplayName && {
                    model_slug: store.getState().userData.modelSlug,
                    model_display_name:
                        store.getState().userData.modelDisplayName,
                }),
                ...(userData.plate && {
                    plate: userData.plate.toUpperCase(),
                }),
                ...(userData.additional_info && {
                    additional_info: userData.additional_info,
                }),
                company_code: getDynamicDealerCode(),
                dealer_name: getDynamicDealerName(),
                privacy_service: userData.service,
                privacy_origin: configuration.lead_inbox.privacy_origin,
                privacy_profile_vgi: userData.privacyProfileVgi,
                privacy_marketing_vgi: userData.privacyMarketingVgi,
                full_url: window.location.href,
                ...(!isEmpty(priceListData) && {
                    price_list_additional_info: priceListData,
                }),
            })
            .then((response) => {
                setLoadingForm(false)
                dispatch(showThanksPage(true))
                dispatch(setPersonalData(userData))
                dispatch(setSelectedDealerData(selectedDealer))
                dispatch(setRandomCode(response.data.random_code))

                trackingEvent(
                    TrackingAction.VW_BASIC_FORM_SUBMISSION_SUCCESS_MESSAGE_LOAD,
                    {
                        configuration: {
                            CarlineName: defaultModel(),
                        },
                        eventInfo: {
                            ...eventInfoCommon(),
                            contentId:
                                store.getState().configurationPromo
                                    .configuration.form.title[getVersionKey()],
                        },
                        form: {
                            FormType: trackingData.FORM_TYPE,
                            FormName:
                                store.getState().configurationPromo
                                    .configuration.tracking_application_id +
                                ' - ' +
                                store.getState().configurationPromo
                                    .configuration.form.title[getVersionKey()],
                            MarketingConsent: [
                                {
                                    marketing:
                                        store.getState().userData.personalData
                                            .privacyMarketingVgi,
                                    profilazione:
                                        store.getState().userData.personalData
                                            .privacyProfileVgi,
                                    servizio:
                                        store.getState().userData.personalData
                                            .service,
                                },
                            ],
                            FormStart: false,
                            LeadID: trackingData.LEAD_ID + response.data.id,
                            CycleID: cycleID,
                        },
                        process: {
                            StepName: trackingData.STEP_NAME,
                            StepNumber: trackingData.STEP_NUMBER,
                        },
                    }
                )

                resetErrorNotification()
                reset()
            })
            .catch((error) => {
                setLoadingForm(false)

                if (error.response.status === Global.ERROR_422) {
                    Object.keys(error.response.data.errors).map((el) => {
                        showBackendValidationForm(
                            el,
                            error.response.data.errors
                        )
                    })
                    trackingError()
                } else {
                    openAlertSystemLayer()
                    throw new Error(error)
                }
            })
    }

    const onSubmit = (data: UserDataForm) => {
        resetErrorNotification()
        createCoupon(data)
        trackingBasicClick()
    }

    const onError = () => {
        trackingBasicClick()
        trackingError()
    }

    const preCompileForm = () => {
        if (csrfToken) {
            setLoadingForm(true)

            axiosInstance
                .get(authProxyUserEndpoint)
                .then((res) => {
                    if (res.data.given_name) {
                        setValue('name', res.data.given_name)
                        clearErrors('name')
                    }

                    if (res.data.family_name) {
                        setValue('surname', res.data.family_name)
                        clearErrors('surname')
                    }

                    if (res.data.phone_number) {
                        setValue('telephone', res.data.phone_number)
                        clearErrors('telephone')
                    }

                    if (res.data.email) {
                        setValue('email', res.data.email)
                        clearErrors('email')
                    }

                    setLoadingForm(false)
                })
                .catch((errors) => {
                    dispatch(setLoading(false))
                    throw new Error(errors)
                })
        } else {
            openAlertSystemLayer()
            throw new Error('csrfToken missed')
        }
    }

    const trackingBasicClick = () => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_CTA_CLICK, {
            configuration: {
                CarlineName: defaultModel(),
            },
            eventInfo: {
                ...eventInfoCommon(),
                contentId:
                    store.getState().configurationPromo.configuration.form
                        .title[getVersionKey()],
            },
            form: {
                FormName:
                    store.getState().configurationPromo.configuration
                        .tracking_application_id +
                    ' - ' +
                    store.getState().configurationPromo.configuration.form
                        .title[getVersionKey()],
                FormType: trackingData.FORM_TYPE,
            },
            link: {
                name: labelSubmit,
                url: window.location.href,
            },
        })
    }

    const trackingError = () => {
        trackingEvent(TrackingAction.VW_BASIC_FORM_ERROR_MESSAGE_LOAD, {
            form: {
                ErrorFormFields: Object.keys(errors),
                CycleID: cycleID,
            },
        })
    }

    return (
        <>
            {logged ? (
                <PreCompiledCTA
                    tag="button"
                    emphasis="secondary"
                    size="small"
                    onClick={preCompileForm}
                    ariaLabel={t('layers.couponRequest.precompiledCtaLabel')}
                    title={t('layers.couponRequest.precompiledCtaLabel')}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(
                                'layers.couponRequest.precompiledCtaLabel'
                            ),
                        }}
                    />
                </PreCompiledCTA>
            ) : undefined}

            <LayerForm
                onSubmit={handleSubmit(onSubmit, onError)}
                className={!logged ? 'no-logged' : undefined}
            >
                <div>
                    <FieldContainer>
                        <TextInput
                            {...register('name', {
                                minLength: {
                                    value: Number(
                                        Global.MINiMUM_FORM_FIELD_LENGTH
                                    ),
                                    message: t('form.error.minLength'),
                                },
                                required: {
                                    value: true,
                                    message: t('form.error.required'),
                                },
                            })}
                            appearance={
                                errors.name
                                    ? InputAppearance.Error
                                    : InputAppearance.Default
                            }
                            message={
                                errors.name ? errors.name.message : undefined
                            }
                            label={t('form.name')}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <TextInput
                            {...register('surname', {
                                minLength: {
                                    value: Number(
                                        Global.MINiMUM_FORM_FIELD_LENGTH
                                    ),
                                    message: t('form.error.minLength'),
                                },
                                required: {
                                    value: true,
                                    message: t('form.error.required'),
                                },
                            })}
                            appearance={
                                errors.surname
                                    ? InputAppearance.Error
                                    : InputAppearance.Default
                            }
                            message={
                                errors.surname
                                    ? errors.surname.message
                                    : undefined
                            }
                            label={t('form.surname')}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <TextInput
                            {...register('email', {
                                pattern: {
                                    value: emailRegExp,
                                    message: t('form.error.emailNotValid'),
                                },
                                required: {
                                    value: true,
                                    message: t('form.error.required'),
                                },
                            })}
                            appearance={
                                errors.email
                                    ? InputAppearance.Error
                                    : InputAppearance.Default
                            }
                            message={
                                errors.email ? errors.email.message : undefined
                            }
                            label={t('form.email')}
                        />
                    </FieldContainer>

                    <FieldContainer>
                        <TextInput
                            {...register('telephone', {
                                required: {
                                    value: true,
                                    message: t('form.error.required'),
                                },
                            })}
                            appearance={
                                errors.telephone
                                    ? InputAppearance.Error
                                    : InputAppearance.Default
                            }
                            message={
                                errors.telephone
                                    ? errors.telephone.message
                                    : undefined
                            }
                            label={t('form.telephone')}
                        />
                    </FieldContainer>

                    {logged ? (
                        <CTAVum
                            tag="a"
                            href="#"
                            emphasis="tertiary"
                            onClick={(e: {preventDefault: () => void}) => {
                                e.preventDefault()
                                openDisclaimerLayer()
                            }}
                            title={t('layers.disclaimer.ctaDataDisclaimer')}
                            ariaLabel={t('layers.disclaimer.ctaDataDisclaimer')}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(
                                        'layers.disclaimer.ctaDataDisclaimer'
                                    ),
                                }}
                            />
                        </CTAVum>
                    ) : undefined}

                    {plateField.enabled ? (
                        <FieldTextInputUppercase>
                            <TextInput
                                {...register('plate', {
                                    required: {
                                        value: true,
                                        message: t('form.error.required'),
                                    },
                                })}
                                appearance={
                                    errors.plate
                                        ? InputAppearance.Error
                                        : InputAppearance.Default
                                }
                                message={
                                    errors.plate
                                        ? errors.plate.message
                                        : undefined
                                }
                                label={t('form.plate')}
                            />
                        </FieldTextInputUppercase>
                    ) : undefined}

                    {modelsField.enabled ? (
                        <FieldContainer>
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('form.error.required'),
                                    },
                                }}
                                name="models"
                                render={({field: {onChange}}) => (
                                    <Select
                                        appearance={
                                            errors.models ? `error` : `success`
                                        }
                                        message={errors.models?.message ?? ``}
                                        defaultValue=""
                                        onChange={(val) => {
                                            onChange(val)
                                            dispatch(
                                                setSelectedModelSlug(
                                                    (
                                                        val.target as HTMLInputElement
                                                    )?.value
                                                )
                                            )
                                            dispatch(
                                                setSelectedModelDisplayName(
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    val.target.options[
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        val.target.selectedIndex
                                                    ].text
                                                )
                                            )

                                            trackingEvent(
                                                TrackingAction.VW_BASIC_MODEL_SELECT_CLICK,
                                                {
                                                    configuration: {
                                                        ModelName: (
                                                            val.target as HTMLInputElement
                                                        )?.value,
                                                    },
                                                }
                                            )
                                        }}
                                        label={t('form.models.label')}
                                    >
                                        <option value="">
                                            {t('form.models.default')}
                                        </option>

                                        {Object.keys(
                                            modelsField.enabled_models
                                        ).map((key) => {
                                            return (
                                                <option value={key} key={key}>
                                                    {
                                                        modelsField
                                                            .enabled_models[
                                                            String(key)
                                                        ]
                                                    }
                                                </option>
                                            )
                                        })}
                                    </Select>
                                )}
                            />
                        </FieldContainer>
                    ) : undefined}

                    {additionalInfoField.enabled ? (
                        <FieldContainer>
                            <Controller
                                control={control}
                                name="additional_info"
                                render={({field: {onChange}}) => (
                                    <TextArea
                                        rows={3}
                                        maxLength={LIMIT_TEXT_AREA}
                                        onChange={(val) => {
                                            onChange(val)
                                        }}
                                        message={
                                            errors.additional_info
                                                ? errors.additional_info.message
                                                : undefined
                                        }
                                        appearance={InputAppearance.Default}
                                        label={
                                            additionalInfoField.label
                                                ? additionalInfoField.label
                                                : undefined
                                        }
                                    />
                                )}
                            />
                        </FieldContainer>
                    ) : undefined}
                </div>

                {!isDealerWeb ? (
                    <FormFieldsContainer>
                        <FormFieldsContainerTitle
                            tag={TextTag.h5}
                            bold
                            appearance={TokenTextAppearance.headline200}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t('form.dealerInfo.title'),
                                }}
                            />
                        </FormFieldsContainerTitle>

                        {!isEmpty(allProvinces) ? (
                            <FieldContainer>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('form.error.required'),
                                        },
                                    }}
                                    name="province"
                                    render={({field: {onChange}}) => (
                                        <Select
                                            appearance={
                                                errors.province
                                                    ? `error`
                                                    : `success`
                                            }
                                            message={
                                                errors.province?.message ?? ``
                                            }
                                            defaultValue=""
                                            onChange={(val) => {
                                                onChange(val)
                                                filterDealersByProvince(
                                                    val.currentTarget.value
                                                )
                                            }}
                                            label={t('form.province.label')}
                                        >
                                            <option value="">
                                                {t('form.province.default')}
                                            </option>
                                            {allProvinces.map(
                                                (option: Province) => (
                                                    <option
                                                        value={option.code}
                                                        key={option.id}
                                                    >
                                                        {option.name}
                                                    </option>
                                                )
                                            )}
                                        </Select>
                                    )}
                                />
                            </FieldContainer>
                        ) : undefined}

                        {!isEmpty(allDealers) ? (
                            <FieldContainer>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('form.error.required'),
                                        },
                                    }}
                                    name="dealer"
                                    render={({field: {onChange}}) => (
                                        <Select
                                            appearance={
                                                errors.dealer
                                                    ? `error`
                                                    : `success`
                                            }
                                            message={
                                                errors.dealer?.message ?? ``
                                            }
                                            defaultValue=""
                                            onChange={(val) => {
                                                onChange(val)
                                                saveDealerSelected(
                                                    val.currentTarget.value
                                                )
                                            }}
                                            label={t('form.dealer.label')}
                                        >
                                            <option value="">
                                                {t('form.dealer.default')}
                                            </option>

                                            {!isEmpty(filteredDealers) ? (
                                                <>
                                                    {filteredDealers.map(
                                                        (
                                                            option: DealerData
                                                        ) => (
                                                            <option
                                                                key={option.id}
                                                                value={
                                                                    option.companyCode
                                                                }
                                                            >
                                                                {capitalizeWords(
                                                                    getDealerInfoOption(
                                                                        option
                                                                    )
                                                                )}
                                                            </option>
                                                        )
                                                    )}
                                                </>
                                            ) : undefined}
                                        </Select>
                                    )}
                                />
                            </FieldContainer>
                        ) : undefined}
                    </FormFieldsContainer>
                ) : undefined}

                <FormFieldsContainer>
                    <FormFieldsContainerTitle
                        tag={TextTag.h5}
                        bold
                        appearance={TokenTextAppearance.headline200}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('form.privacy.title'),
                            }}
                        />
                    </FormFieldsContainerTitle>

                    <CTA
                        tag="a"
                        href={configuration.form.privacy_policy_url}
                        emphasis="tertiary"
                        target="_blank"
                        title={t('form.privacy.labelCTALabel')}
                        ariaLabel={t('form.privacy.labelCTALabel')}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('form.privacy.labelCTA'),
                            }}
                        />
                    </CTA>
                    <PrivacyText
                        tag={TextTag.p}
                        appearance={TokenTextAppearance.copy200}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('form.privacy.text'),
                            }}
                        />
                    </PrivacyText>
                    <Fieldset>
                        <Checkbox
                            {...register('service', {
                                required: {
                                    value: true,
                                    message: t('form.error.required'),
                                },
                            })}
                            appearance={
                                errors.service
                                    ? InputAppearance.Error
                                    : InputAppearance.Default
                            }
                            message={
                                errors.service
                                    ? errors.service.message
                                    : undefined
                            }
                            label={t('form.privacy.service')}
                            value=""
                        />
                        <Checkbox
                            {...register('privacyProfileVgi')}
                            label={t('form.privacy.privacyProfileVgi')}
                            value=""
                        />
                        <Checkbox
                            {...register('privacyMarketingVgi')}
                            label={t('form.privacy.privacyMarketingVgi')}
                            value=""
                        />
                    </Fieldset>
                </FormFieldsContainer>

                {showNotification ? (
                    <>
                        <Infobox
                            ariaLive="assertive"
                            appearance={InfoboxAppearance.Error}
                        >
                            <Text tag={TextTag.p}>{NotificationText}</Text>
                        </Infobox>
                    </>
                ) : undefined}
                <SubmitContainer>
                    <CTA
                        tag="button"
                        emphasis="primary"
                        title={labelSubmit}
                        ariaLabel={labelSubmit ? labelSubmit : undefined}
                    >
                        <span
                            dangerouslySetInnerHTML={{
                                __html: labelSubmit ? labelSubmit : '',
                            }}
                        />
                    </CTA>
                </SubmitContainer>
            </LayerForm>

            {!isDealerWeb &&
            (loadingForm || isEmpty(allProvinces) || isEmpty(allDealers)) ? (
                <Loading />
            ) : undefined}
        </>
    )
}
