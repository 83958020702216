import {LayerManagerV24} from '@volkswagen-onehub/layer-manager'
import React from 'react'
import {Provider} from 'react-redux'
import store from '../../store'
import {DisclaimerVum} from '../DisclaimerVum'

function disclaimerLayer(): React.JSX.Element {
    return (
        <Provider store={store}>
            <DisclaimerVum />
        </Provider>
    )
}

let localLayerManager: LayerManagerV24
let layer: {close: () => void}

export const setDisclaimerLayerHandle = (layerManager: LayerManagerV24) => {
    localLayerManager = layerManager
}

export const openDisclaimerLayer = () => {
    layer = localLayerManager.openDisclaimerLayer(disclaimerLayer, {})
}

export const closeDisclaimerLayer = () => {
    layer.close()
}
