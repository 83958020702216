import {
    Container,
    ContainerGutter,
    ContainerPadding,
    styled,
    CTA,
    Text,
} from '@volkswagen-onehub/components-core'
import {Close} from '@volkswagen-onehub/icons-core'

import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {closeDisclaimerLayer} from './launchers/DisclaimerLayerHandle'
import {RootState} from '../rootReducer'

const ContainerDisclaimer = styled.div`
    padding-right: 4rem;
`

const CTAClose = styled(CTA)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

export function DisclaimerVum(): React.JSX.Element {
    const {t} = useTranslation('fa-coupon')

    const configuration = useSelector(
        (state: RootState) => state.configurationPromo.configuration
    )

    return (
        <>
            <Container
                gutter={ContainerGutter.static300}
                padding={ContainerPadding.static350}
                wrap="always"
            >
                <ContainerDisclaimer>
                    <Text>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('layers.disclaimer.dataDisclaimer', {
                                    promo: configuration.name,
                                }),
                            }}
                        />
                    </Text>
                    <CTAClose
                        tag="button"
                        emphasis="tertiary"
                        ariaLabel="Close iteraction layer"
                        iconPosition="top"
                        onClick={closeDisclaimerLayer}
                        icon={<Close />}
                    />
                </ContainerDisclaimer>
            </Container>
        </>
    )
}
