import {
    InteractionLayerSizeV2,
    LayerManagerV2,
    ShimBackgroundTypeV2,
} from '@volkswagen-onehub/layer-manager'

import React from 'react'
import {Provider} from 'react-redux'
import store from '../../store'
import {CouponRequestLayer} from '../CouponRequest'
import {
    setPersonalData,
    setResentEmail,
    showThanksPage,
} from '../../../features/userData/userDataSlice'
import {setShowCouponLayer} from '../../../features/general/generalSlice'

function couponRenderLayer(): React.JSX.Element {
    return (
        <Provider store={store}>
            <CouponRequestLayer />
        </Provider>
    )
}

let localLayerManager: LayerManagerV2
let layer: {close: () => void}

export const setCouponLayerHandle = (layerManager: LayerManagerV2) => {
    localLayerManager = layerManager
}

export const resetFormAfterSuccess = () => {
    store.dispatch(showThanksPage(false))
    store.dispatch(setResentEmail(false))
    store.dispatch(setPersonalData({}))
}

export const openCouponLayer = () => {
    if (!localLayerManager) return
    layer = localLayerManager.openInteractionLayer(
        couponRenderLayer,
        {},
        {
            size: InteractionLayerSizeV2.C,
            shimBackgroundType: ShimBackgroundTypeV2.OPAQUE,
            userCloseable: true,
            onClose: () => {
                resetFormAfterSuccess()
            },
        }
    )
}

export const closeCouponLayer = () => {
    if (store.getState().general.isDealerWeb) {
        store.dispatch(setShowCouponLayer(false))
        resetFormAfterSuccess()
    } else {
        layer.close()
    }
}
