import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    CTA,
    InteractionLayer,
    InteractionLayerSize,
    LayerWrapper,
    Layout,
    LayoutRowGap,
    ShimLayer,
    styled,
} from '@volkswagen-onehub/components-core'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
    openCouponLayer,
    setCouponLayerHandle,
} from './layers/launchers/CouponRequestLayerHandle'
import {useFeatureServices} from '../use-feature-services'
import {setAlertSystemLayerHandle} from './layers/launchers/AlertSystemLayerHandle'

import {
    setFormCycleID,
    setPrivacyMarketingVgiVersion,
    setPrivacyProfileVgiVersion,
    setSelectedModelDisplayName,
    setSelectedModelSlug,
} from '../features/userData/userDataSlice'
import {useDispatch, useSelector} from 'react-redux'
import {
    AppDispatch,
    fetchAllDealers,
    fetchAllProvinces,
    fetchConfigurationPromo,
} from './thunk'
import {setDisclaimerLayerHandle} from './layers/launchers/DisclaimerLayerHandle'
import {
    setBackendUrl,
    setCampaignCode,
    setDealerWeb,
    setLayout,
    setLoading,
    setPriceList,
    setPriceListData,
    setShowCouponLayer,
    setShowRecoverLayer,
    setSource,
} from '../features/general/generalSlice'
import {Loading} from './components/loading'
import {RootState} from './rootReducer'
import isEmpty from 'lodash.isempty'
import {authServiceHelpers} from '../shared/authServiceHelper'
import {
    openRecoverCouponLayer,
    setRecoverCouponLayerHandle,
} from './layers/launchers/RecoverCouponRequestLayerHandle'
import {CouponRequestLayer} from './layers/CouponRequest'
import {RecoverCouponRequestLayer} from './layers/RecoverCouponRequest'
import {
    generateRandomString,
    getAllQueryParams,
    getVersionKey,
} from '../shared/utilities'
import {setTrackingServiceManager} from '../shared/trackingServiceHelper'
import {setPrefilledDealerData} from '../features/dealers/dealerSlice'
import store from './store'
import {customPartner, Global, partner} from '../shared/variables'
import {setUrlParameters} from '../features/configurationPromo/configurationPromoSlice'
import {DealerView} from './dealer/DealerView'

interface PropsApp {
    featureAppId: string
    backendUrl: string
    campaignCode: string
    privacyProfileVgiVersion: string
    privacyMarketingVgiVersion: string
    source: string
    priceList: string
    layout: string
}

export function App(props: PropsApp): React.JSX.Element {
    const {t, i18n} = useTranslation('fa-coupon')
    const dispatch = useDispatch() as AppDispatch

    const [loadingConfiguration, setLoadingConfiguration] = useState(true)

    const logged = useSelector((state: RootState) => state.general.logged)

    const isDealerWeb = useSelector(
        (state: RootState) => state.general.isDealerWeb
    )
    const showCouponLayer = useSelector(
        (state: RootState) => state.general.showCouponLayer
    )
    const showRecoverLayer = useSelector(
        (state: RootState) => state.general.showRecoverLayer
    )
    const configurationPromo = useSelector(
        (state: RootState) => state.configurationPromo.configuration
    )
    const mainCta = useSelector((state: RootState) =>
        !isEmpty(state.configurationPromo.configuration)
            ? state.configurationPromo.configuration.cta_opening[
                  getVersionKey()
              ]
            : ''
    )

    const RecoverCTAContainer = styled.div`
        padding-bottom: ${({theme}) => theme.size.static100};
    `

    const AppContainer = styled.div`
        &.dw {
            padding: 0;
        }

        &.dw-layer-opened {
            z-index: 10000;
            position: relative;
        }
    `

    const {'layer-manager': layerManager} = useFeatureServices() as any
    const {'vw:authService': provider} = useFeatureServices() as any
    const {'vw:authservice-config': authserviceConfig} =
        useFeatureServices() as any
    const {'service-config-provider': serviceConfigProvider} =
        useFeatureServices() as any
    const {tracking: trackingManager} = useFeatureServices() as any
    const {'ipc-service': ipcService}: any = useFeatureServices() as any

    const authService = provider?.register('market')

    if (typeof props.backendUrl !== 'undefined') {
        dispatch(setBackendUrl(props.backendUrl))
    }

    if (typeof props.privacyProfileVgiVersion !== 'undefined') {
        dispatch(setPrivacyProfileVgiVersion(props.privacyProfileVgiVersion))
    }

    if (typeof props.campaignCode !== 'undefined') {
        dispatch(setCampaignCode(props.campaignCode))
    }

    if (typeof props.source !== 'undefined') {
        dispatch(setSource(props.source))
    }

    if (typeof props.priceList !== 'undefined') {
        dispatch(setPriceList(props.priceList))
    }

    if (typeof props.layout !== 'undefined') {
        dispatch(setLayout(props.layout))
    }

    if (typeof props.privacyMarketingVgiVersion !== 'undefined') {
        dispatch(
            setPrivacyMarketingVgiVersion(props.privacyMarketingVgiVersion)
        )
    }

    useEffect(() => {
        setCouponLayerHandle(layerManager)
        setAlertSystemLayerHandle(layerManager)
        setDisclaimerLayerHandle(layerManager)
        setRecoverCouponLayerHandle(layerManager)

        setTrackingServiceManager(trackingManager)
    }, [])

    useEffect(() => {
        if (
            props.source === Global.SERVICE_WEB_SOURCE ||
            props.source === Global.DEALER_WEB_SOURCE
        ) {
            dispatch(setDealerWeb(true))
        }
    }, [props.source])

    useEffect(() => {
        if (isDealerWeb) {
            dispatch(
                setPrefilledDealerData(customPartner ? customPartner : partner)
            )
        }
    }, [isDealerWeb])

    useEffect(() => {
        dispatch(setLoading(true))

        dispatch(fetchConfigurationPromo()).then(() => {
            setLoadingConfiguration(false)

            if (!store.getState().general.isDealerWeb) {
                dispatch(fetchAllProvinces())
                dispatch(fetchAllDealers())
            } else {
                dispatch(setLoading(false))
            }

            const paramsFromUrl: Record<string, string> = getAllQueryParams(),
                allowed_params =
                    store.getState().configurationPromo.configuration
                        .allowed_parameters

            const filtered_params: Record<string, string> = Object.keys(
                paramsFromUrl
            )
                .filter((key: string) => allowed_params.includes(key))
                .reduce((obj: Record<string, string>, key: string) => {
                    obj[String(key)] = paramsFromUrl[String(key)]
                    return obj
                }, {})

            dispatch(setUrlParameters(filtered_params))
            i18n.changeLanguage(
                store.getState().configurationPromo.configuration.brand
            )
        })
    }, [props.campaignCode])

    useEffect(() => {
        if (logged === undefined) {
            dispatch(setLoading(true))
        } else {
            dispatch(setLoading(false))
        }

        if (authService) {
            authServiceHelpers(
                authService,
                authserviceConfig,
                serviceConfigProvider
            )
        }
    }, [logged])

    const dynamicOpenCouponLayer = () => {
        dispatch(setFormCycleID(generateRandomString(Global.CYCLE_ID_LENGTH)))
        dispatch(setSelectedModelDisplayName(''))
        dispatch(setSelectedModelSlug(''))
        if (isDealerWeb) {
            dispatch(setShowRecoverLayer(false))
            dispatch(setShowCouponLayer(true))
        } else openCouponLayer()
    }

    const dynamicOpenRecoverLayer = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault()
        dispatch(setFormCycleID(generateRandomString(Global.CYCLE_ID_LENGTH)))

        if (isDealerWeb) {
            dispatch(setShowCouponLayer(false))
            dispatch(setShowRecoverLayer(true))
        } else {
            openRecoverCouponLayer()
        }
    }

    if (ipcService) {
        const setPriceListDataListener = {
            id: 'onChangePriceListData',
            receive: () => {
                const priceListData = ipcService.get(
                    'fs-coupon',
                    'priceListData'
                )
                if (priceListData) {
                    dispatch(setPriceListData(priceListData))
                    openCouponLayer()
                }
            },
        }
        const recoverFormLayerListener = {
            id: 'onOpenRecoverFormLayer',
            receive: () => {
                openRecoverCouponLayer()
            },
        }

        ipcService.subscribe('setPriceListData', setPriceListDataListener)
        ipcService.subscribe('recoverFormLayer', recoverFormLayerListener)
    }

    return (
        <AppContainer
            className={`${isDealerWeb ? 'dw' : undefined} ${
                isDealerWeb && (showCouponLayer || showRecoverLayer)
                    ? 'dw-layer-opened'
                    : undefined
            }`}
        >
            {!isEmpty(configurationPromo) ? (
                <Container
                    padding={
                        isDealerWeb
                            ? {
                                  left: {
                                      [Breakpoints.default]:
                                          ContainerPadding.grid002,
                                      [Breakpoints.b560]:
                                          ContainerPadding.grid006,
                                      [Breakpoints.b1600]:
                                          ContainerPadding.grid007,
                                  },
                                  right: {
                                      [Breakpoints.default]:
                                          ContainerPadding.grid002,
                                      [Breakpoints.b560]:
                                          ContainerPadding.grid006,
                                      [Breakpoints.b1600]:
                                          ContainerPadding.grid007,
                                  },
                              }
                            : {
                                  left: ContainerPadding.grid002,
                                  right: ContainerPadding.grid002,
                              }
                    }
                    wrap="always"
                >
                    {!loadingConfiguration ? (
                        <>
                            {!props.priceList ? (
                                <>
                                    {props.layout === Global.DEALER_LAYOUT ? (
                                        <DealerView />
                                    ) : (
                                        <Layout rowGap={LayoutRowGap.static300}>
                                            <CTA
                                                tag="button"
                                                emphasis="primary"
                                                onClick={dynamicOpenCouponLayer}
                                                ariaLabel={
                                                    mainCta
                                                        ? mainCta
                                                        : undefined
                                                }
                                            >
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: mainCta
                                                            ? mainCta
                                                            : undefined,
                                                    }}
                                                />
                                            </CTA>

                                            {configurationPromo.coupon
                                                .recoverable ? (
                                                <RecoverCTAContainer>
                                                    <CTA
                                                        tag="a"
                                                        emphasis="tertiary"
                                                        href="#"
                                                        onClick={(e) =>
                                                            dynamicOpenRecoverLayer(
                                                                e
                                                            )
                                                        }
                                                        ariaLabel={t(
                                                            'cta.recover'
                                                        )}
                                                    >
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    'cta.recover'
                                                                ),
                                                            }}
                                                        />
                                                    </CTA>
                                                </RecoverCTAContainer>
                                            ) : undefined}
                                        </Layout>
                                    )}
                                </>
                            ) : undefined}
                        </>
                    ) : (
                        <Loading />
                    )}
                </Container>
            ) : undefined}

            <LayerWrapper>
                {isDealerWeb && (showCouponLayer || showRecoverLayer) ? (
                    <ShimLayer />
                ) : undefined}

                {isDealerWeb && showCouponLayer ? (
                    <InteractionLayer size={InteractionLayerSize.C} active>
                        <Container
                            gutter={ContainerGutter.static300}
                            padding={ContainerPadding.grid001}
                            wrap="always"
                        >
                            <CouponRequestLayer />
                        </Container>
                    </InteractionLayer>
                ) : undefined}

                {isDealerWeb && showRecoverLayer ? (
                    <InteractionLayer size={InteractionLayerSize.C} active>
                        <Container
                            gutter={ContainerGutter.static300}
                            padding={ContainerPadding.grid001}
                            wrap="always"
                        >
                            <RecoverCouponRequestLayer />
                        </Container>
                    </InteractionLayer>
                ) : undefined}
            </LayerWrapper>
        </AppContainer>
    )
}
