import {Global} from './variables'
import store from '../app/store'

export const PARTNER_DEFAULT = {
    informationBnr: undefined,
    informationName: undefined,
}

const KEYS = {
    informationBnr: 'dealer_id',
    informationName: 'ragione-sociale',
}
export const getPartner = (partner: {[x: string]: string}) => {
    const informationBnr = partner[KEYS.informationBnr]

    return {
        informationBnr: informationBnr
            .trim()
            .substring(0, informationBnr.length - 2),
        informationName: partner[KEYS.informationName].trim(),
    }
}

export const getCustomPartner = (partner: {[x: string]: string}) => {
    return {
        informationBnr: partner[KEYS.informationBnr].trim(),
        informationName: partner[KEYS.informationName].trim(),
    }
}

export const getVersionKey = () => {
    return store.getState().general.isDealerWeb
        ? Global.DW_SW_KEY
        : Global.NATIONAL_KEY
}

export const getSourceKey = () => {
    if (
        store.getState().general.isDealerWeb &&
        store.getState().general.source === Global.DEALER_WEB_SOURCE
    ) {
        return Global.DEALER_WEB_SOURCE
    } else if (
        store.getState().general.isDealerWeb &&
        store.getState().general.source === Global.SERVICE_WEB_SOURCE
    ) {
        return Global.SERVICE_WEB_SOURCE
    } else {
        return Global.NATIONAL_KEY
    }
}

export const generateRandomString = (length: number) => {
    return Array.from(Array(length), () =>
        Math.floor(Math.random() * 36).toString(36)
    ).join('')
}

export const getAllQueryParams = () => {
    const queryParams: Record<string, string> = {}
    const searchParams = new URLSearchParams(window.location.search)

    for (const param of searchParams.entries()) {
        queryParams[param[0]] = param[1]
    }

    return queryParams
}

export const capitalizeWords = (str: string) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

export const getDealerInfoOption = (option: any) => {
    return `${option.company} - ${option.address}${
        option.streetNumber ? ' ' + option.streetNumber : ''
    }, ${option.city}`
}
