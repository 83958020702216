import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {AuthServiceToken} from '../../types/authServiceToken'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        featureAppId: '',
        backendUrl: '',
        campaignCode: '',
        source: '',
        logged: undefined,
        loading: false,
        isDealerWeb: false,
        showCouponLayer: false,
        showRecoverLayer: false,
        priceList: '',
        priceListData: null,
        layout: '',
    },
    reducers: {
        setFeatureAppId(state: RootState, action: PayloadAction<string>): void {
            state.featureAppId = action.payload
        },
        setBackendUrl(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.backendUrl = action.payload
        },
        setCampaignCode(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.campaignCode = action.payload
        },
        setSource(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.source = action.payload
        },
        setPriceList(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.priceList = action.payload
        },
        setLayout(
            state: RootState,
            action: PayloadAction<string | undefined>
        ): void {
            state.layout = action.payload
        },
        setPriceListData(
            state: RootState,
            action: PayloadAction<object | null>
        ): void {
            state.priceListData = action.payload
        },
        setLoading(state: RootState, action: PayloadAction<boolean>): void {
            state.loading = action.payload
        },
        setLogged(state: RootState, action: PayloadAction<boolean>): void {
            state.logged = action.payload
        },
        setAuthServiceToken(
            state: RootState,
            action: PayloadAction<AuthServiceToken>
        ): void {
            state.authServiceToken = action.payload
        },
        setDealerWeb(state: RootState, action: PayloadAction<boolean>): void {
            state.isDealerWeb = action.payload
        },
        setShowCouponLayer(
            state: RootState,
            action: PayloadAction<boolean>
        ): void {
            state.showCouponLayer = action.payload
        },
        setShowRecoverLayer(
            state: RootState,
            action: PayloadAction<boolean>
        ): void {
            state.showRecoverLayer = action.payload
        },
    },
})

export const {
    setFeatureAppId,
    setDealerWeb,
    setBackendUrl,
    setPriceList,
    setPriceListData,
    setSource,
    setCampaignCode,
    setLoading,
    setLogged,
    setAuthServiceToken,
    setShowCouponLayer,
    setShowRecoverLayer,
    setLayout,
} = generalSlice.actions

export default generalSlice.reducer
