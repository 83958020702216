import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../app/rootReducer'
import {fetchAllDealers} from '../../app/thunk'
import {DealerData, PrefilledDealerData} from '../../types/dealer'

export const dealerSlice = createSlice({
    name: 'dealers',
    initialState: {
        allDealers: [],
        selected: null,
        prefilledDealerData: null,
    },
    reducers: {
        setDealers(
            state: RootState,
            action: PayloadAction<DealerData[]>
        ): void {
            state.allDealers = action.payload
        },
        setSelectedDealerData(
            state: RootState,
            action: PayloadAction<DealerData | null>
        ): void {
            state.selected = action.payload
        },
        setPrefilledDealerData(
            state: RootState,
            action: PayloadAction<PrefilledDealerData | any>
        ): void {
            state.prefilledDealerData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchAllDealers.fulfilled,
            (state: RootState, action) => {
                state.allDealers = action.payload
            }
        )
    },
})

export const {setSelectedDealerData, setPrefilledDealerData} =
    dealerSlice.actions

export default dealerSlice.reducer
