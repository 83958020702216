import axios from 'axios'
import store from '../app/store'
import {setAuthServiceToken, setLogged} from '../features/general/generalSlice'
import {AuthServiceToken} from '../types/authServiceToken'
import {IdkEndpoints} from './variables'

let fag: string
let urlOrigin: string

export let authProxyUserEndpoint: string

const csrfCookieName = IdkEndpoints.CSRF_TOKEN

export const csrfToken =
    document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter(
            (cookie) =>
                cookie.substring(0, csrfCookieName.length + 1) ===
                `${csrfCookieName}=`
        )
        .map((cookie) =>
            decodeURIComponent(cookie.substring(csrfCookieName.length + 1))
        )[0] || null

export const axiosInstance = axios.create({
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken ? csrfToken : '',
    },
})

export function authServiceHelpers(
    authService: {
        isAuthenticated: () => Promise<any>
        tokens: AuthServiceToken
    },
    authServiceConfig: {legalEntityMapping: {market: [{fag: string}]}},
    serviceConfigProvider: {configs: {[x: string]: {urlOrigin: string}}}
) {
    authService.isAuthenticated().then((authenticated) => {
        store.dispatch(setLogged(authenticated))
        store.dispatch(setAuthServiceToken(authService.tokens))

        const markets = authServiceConfig.legalEntityMapping.market[0]
        fag = markets.fag
        urlOrigin = serviceConfigProvider.configs['auth-proxy'].urlOrigin

        authProxyUserEndpoint = `${urlOrigin}/${fag}${IdkEndpoints.USER}`
    })
}
