import {TrackingManagerV1} from '@volkswagen-onehub/tracking-service'
import store from '../app/store'

let localTrackingManager: TrackingManagerV1

export const setTrackingServiceManager = (
    trackingManager: TrackingManagerV1
) => {
    localTrackingManager = trackingManager
}

export enum TrackingAction {
    VW_BASIC_FORM_ERROR_MESSAGE_LOAD = 'VWBasic_FormErrorMessage_Load',
    VW_BASIC_FORM_SUBMISSION_SUCCESS_MESSAGE_LOAD = 'VWBasic_FormSubmissionSuccessMessage_Load',
    VW_BASIC_LINK_CLICK = 'VWBasic_Link_Click',
    VW_BASIC_MODEL_SELECT_CLICK = 'VWBasic_ModelSelect_Click',
    VW_BASIC_BUTTON_CLICK = 'VWBasic_Button_Click',
    VW_BASIC_FORM_LAYER_LOAD = 'VWBasic_FormLayer_Load',
    VW_BASIC_FORM_LAYER_CLOSE_CLICK = 'VWBasic_FormLayerClose_Click',
    VW_BASIC_FORM_CTA_CLICK = 'VWBasic_FormCTA_Click',
}

export const trackingData = {
    APPLICATION_ID: 'VWCMSD6',
    DW_SW_APPLICATION_ID: 'VWLocalITDealerWebD6',
    FEATURE_APP_ID: 'Voucher-D6-IT',
    BRAND: {
        VW: 'passenger',
        VI: 'commercial',
    },
    FORM_TYPE: 'servicerequest',
    STEP_NAME: 'Thank you page',
    STEP_NUMBER: '2',
    LEAD_ID: 'IT-SFACPN-',
    COUNTRY: 'IT',
    LANGUAGE: 'it',
    COMMERCE: false,
    PERSONALIZATION: false,
    LOGIN_STATUS: false,
    ORIENTATION_HORIZONTAL: 'h',
    ORIENTATION_VERTICAL: 'v',
    PAGE_CATEGORY: 'Area Clienti',
    DEFAULT_MODEL: {
        VW: 'Gamma Volkswagen',
        VI: 'Gamma VIC',
    },
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const vwdata = (window.vwdata = window.vwdata || [])

export const eventInfoCommon = () => {
    return {
        featureAppId: trackingData.FEATURE_APP_ID,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        brand: trackingData.BRAND[
            store.getState().configurationPromo.configuration.brand
        ],
    }
}

export const defaultModel = () => {
    return !store.getState().userData.modelDisplayName
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          trackingData.DEFAULT_MODEL[
              store.getState().configurationPromo.configuration.brand
          ]
        : store.getState().userData.modelDisplayName
}

const getPageName = (applicationPageName: string) => {
    return `app : ${applicationPageName}`
}

const getBreakpoint = () => {
    let breakpoint = ''

    if (window.innerWidth < 480) {
        breakpoint = '0'
    } else if (window.innerWidth >= 480 && window.innerWidth < 639) {
        breakpoint = '480'
    } else if (window.innerWidth >= 639 && window.innerWidth < 1024) {
        breakpoint = '639'
    } else if (window.innerWidth >= 1024 && window.innerWidth < 1281) {
        breakpoint = '1024'
    } else if (window.innerWidth >= 1281) {
        breakpoint = '1281'
    }

    return breakpoint
}

const getBrowserOrientation = () => {
    return window.innerHeight > window.innerWidth
        ? trackingData.ORIENTATION_VERTICAL
        : trackingData.ORIENTATION_HORIZONTAL
}

const getBrowserData = () => {
    return {
        browserResolutionHeight:
            document.documentElement.clientHeight.toString(),
        browserResolutionWidth: document.documentElement.clientWidth.toString(),
        browserResolutionBreakpoint: getBreakpoint(),
        browserResolutionOrientation: getBrowserOrientation(),
    }
}

export const trackingEvent = (event: string, configuration?: any) => {
    if (!store.getState().general.isDealerWeb) {
        return localTrackingManager.track(event, {
            environment: {
                applicationId: trackingData.APPLICATION_ID,
                featureAppVariant:
                    store.getState().configurationPromo.configuration
                        .tracking_application_id,
                featureAppId: trackingData.FEATURE_APP_ID,
                commerce: trackingData.COMMERCE,
            },
            ...(store.getState().dealers.selected && {
                partner: {
                    informationBnr:
                        store.getState().dealers.selected.companyCode,
                    informationName: store.getState().dealers.selected.company,
                    informationZIP: store.getState().dealers.selected.zipCode,
                },
            }),
            personalization: {
                status: trackingData.PERSONALIZATION,
            },
            ...(typeof store.getState().general.logged !== 'undefined' && {
                user: {
                    loginStatus: store.getState().general.logged,
                },
            }),
            ...configuration,
        })
    } else {
        return vwdata.push({
            environment: {
                applicationId: trackingData.DW_SW_APPLICATION_ID,
                country: trackingData.COUNTRY,
                language: trackingData.LANGUAGE,
                commerce: trackingData.COMMERCE,
            },
            ...(store.getState().general.isDealerWeb && {
                partner: {
                    informationBnr:
                        store.getState().dealers.prefilledDealerData
                            .informationBnr,
                    informationName:
                        store.getState().dealers.prefilledDealerData
                            .informationName,
                },
            }),
            user: {
                loginStatus: trackingData.LOGIN_STATUS,
                ...getBrowserData(),
            },
            personalization: {
                status: trackingData.PERSONALIZATION,
            },
            ...configuration,
            eventInfo: {
                ...(configuration.eventInfo && configuration.eventInfo),
                eventAction: event,
                pageName: getPageName(
                    store.getState().configurationPromo.configuration.name
                ),
                pageCategory: trackingData.PAGE_CATEGORY,
                pageId: window.location.host + window.location.pathname,
            },
        })
    }
}
